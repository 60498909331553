<template>
  <div class="step-container">
    <header>
      <div class="back" @click="$emit('close')">
        <img src="/assets/images/NavBackButton.svg" alt="" />
      </div>
      <div class="title">
        {{ data.name || "NaN" }} ({{ data.files.length }} documents)
      </div>
    </header>
    <section>
      <div class="documents">
        <template v-for="(file, index) in data.files" :key="index">
          <div class="document" @click="goToDocument(file.file)">
            <div class="img">
              <img
                :src="
                  file.file_type === 'png' || file.file_type === 'jpg'
                    ? '/assets/images/img-icon.svg'
                    : '/assets/images/doc-icon.svg'
                "
                alt=""
              />
              <span>.{{ file.file_type }}</span>
            </div>
            <div class="file-desc">
              <div class="name">{{ file.file_name }}</div>
              <div class="others">
                <span class="time">File size: {{ file.file_size }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  methods: {
    goToDocument(file) {
      window.open(this.imgURL + file);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  width: 40rem;
  height: 90vh;
  background: white;
  user-select: none;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 1px 8px #142e6e1a;

  header {
    width: 100%;
    min-height: 8vh;
    height: 8vh;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 8px #142e6e1a;
    z-index: 1;
    background: white;

    .back {
      position: absolute;
      left: 20px;
      cursor: pointer;
    }

    .title {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  section {
    height: 74vh;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .documents {
      display: flex;
      flex-direction: column;
      width: 100%;
      .document {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-bottom: solid 1px #142e6e1a;
        cursor: pointer;

        .img {
          width: 30px;
          height: 30px;
          position: relative;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
            text-transform: lowercase;
          }
        }

        .file-desc {
          .name {
            &:first-letter {
              text-transform: uppercase;
            }
          }
          .time {
            color: #cad6e2;
            font-weight: 400;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.title {
  font-weight: bold;
}
</style>