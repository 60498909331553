<template>
  <div class="step-container">
    <header>
      <div class="back" @click="$emit('close')">
        <img src="/assets/images/NavBackButton.svg" alt="" />
      </div>
      <div class="title">
        {{ entries.length && loaded ? entries[isActive].title : "NaN" }}
      </div>
    </header>
    <section>
      <template v-if="loaded && entries.length">
        <div class="steps">
          <template v-for="(entry, index) in entries" :key="index">
            <span
              class="step"
              :class="{
                active: isActive === index,
                seen: seen.includes(index),
              }"
              @click="handleStep(index)"
            >
              {{ index + 1 }}
            </span>
            <template v-if="index + 1 < entries.length">
              <span class="separator">></span>
            </template>
          </template>
        </div>
        <div class="image" v-if="entries[isActive].images.length">
          <div class="img" ref="mainImage">
            <img
              :src="imgURL + entries[isActive].images[0].image"
              alt=""
              @load="imgLoaded"
            />
            <template v-if="showMarkers">
              <div
                class="hotspot"
                v-for="(marker, index) in entries[isActive].hotspots"
                :key="index"
                :id="marker.number"
                :style="{
                  left: left(marker.position),
                  top: top(marker.position),
                  background: marker.color,
                }"
                :title="marker.description"
              >
                {{ valToLetter(marker) }}
              </div>
            </template>
          </div>
          <div
            class="hotspots"
            v-if="entries[isActive].hotspots.length"
          >
            <div class="title">Hotspots</div>
            <div
              class="marker"
              v-for="(marker, index) in entries[isActive].hotspots"
              :key="index"
            >
              <span>{{ valToLetter(marker)}} |</span>
              <span>{{ marker.description }}</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="description">
          <div class="title">Description</div>
          <span style="white-space: break-spaces;">{{ entries[isActive].description }}</span>
        </div>
        <hr />
        <template v-if="entries[isActive].tools.length">
            <div class="tools">
            <div class="title">Tools</div>
            <template v-for="(tool, i) in entries[isActive].tools" :key="i">
                <div class="tool">
                    <figure>
                        <img v-if="tool.icon" :src="imgURL + tool.icon" alt="tool_icon">
                        <img v-else src="/assets/images/tools.svg" alt="tool_default">
                    </figure>
                    <span>{{ tool.name }}</span>
                </div>
                
            </template>
            </div>
            <hr />
        </template>
        <template v-if="entries[isActive].documents.length">
          <div class="documents">
            <div class="title">Documents</div>
            <template
              v-for="(doc, index) in entries[isActive].documents"
              :key="index"
            >
              <div class="doc-info" @click="goToDocument(doc)">
                <div class="desc">
                  <figure>
                    <img src="/assets/images/doc-icon.svg" alt="doc_icon" />
                    <span>.{{ doc.file_type }}</span>
                  </figure>
                  <div class="doc-desc">
                    <div class="name">{{ doc.name }}</div>
                    <div class="extra">
                      <span class="size">File size: {{ doc.file_size }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <hr />
        </template>
        <template v-if="entries[isActive].measurement.length">
          <div class="measurements">
          <div class="title">Measurements</div>
            <template
              v-for="(measure, index) in entries[isActive].measurement"
              :key="index"
            >
              <div class="measure-info">
                <div class="left">
                  <div class="measure-desc">
                    <div class="title">
                        <span :class="{filled: addedNotes.includes(measure.id) && measure.is_mandatory === 1, unfilled: !addedNotes.includes(measure.id) && measure.is_mandatory === 1}">{{measure.name}}</span>
                        <span v-if="measure.is_mandatory === 1">*</span>
                    </div>
                  </div>
                  <div class="value">
                    <span>{{measure.variable}}:</span>
                    <span class="val">{{measure.value}} {{measure.scale}}</span>
                  </div>
                </div>
                <div class="measure-notes" @click="addNote(measure)">
                  <span>view notes</span>
                </div>
              </div>
            </template>
          </div>
        </template>
        <div v-show="entries[isActive].images.length && !isImgLoaded" class="loading">
          <span class="loader"></span>
        </div>
      </template>
      <template v-else-if="loaded && !entries.length">
        <span class="no-steps">There are no steps created for this guide.</span>
      </template>
      <template v-else>
        <div class="loading">
          <span class="loader"></span>
        </div>
      </template>
    </section>
    <footer v-if="entries.length">
      <span
        class="previous"
        :class="{ hidden: isActive === 0 }"
        @click="handleSeen('less')"
        >Previous</span
      >
      <span class="error" v-if="error.length">{{error}}</span>
      <span
        class="next"
        v-if="isActive < entries.length - 1"
        @click="handleSeen('more')"
        >Next</span
      >
      <span
        class="next"
        v-else
        @click="handleSeen('close')"
        >close</span
      >
    </footer>
    <transition name="slide-up">
      <Notes v-if="showNotes.length" :data="showNotes" @close="showNotes = []" @added="handleAdded"/>
    </transition>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import Notes from './Notes.vue'
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      entries: [],
      isActive: 0,
      seen: [],
      imgWidth: 0,
      imgHeight: 0,
      showMarkers: false,
      showNotes: [],
      isImgLoaded: false,
      addedNotes: [],
      error: '',
      hotspots: []
    };
  },
  
  computed: {
    currentHotspotLength() {
        let counter = 0
        for (let index = 0; index < this.isActive; index++) {
            counter += this.entries[index].hotspots.length
        }
        return counter
    }
  },

  components: {
    Notes
  },

  watch: {
    isActive: {
      immediate: true,
      handler(val) {
        if (!this.seen.includes(val)) this.isImgLoaded = false
      }      
    }
  },

  methods: {
    async getData() {
      this.loaded = false;
      let response = await axiosInstance
        .get(`step?troubleshoot_id=${this.data.id}`)
        .finally(() => (this.loaded = true));
      let responseData = response.data.data;
      this.hotspots = responseData.data.map(el => el.hotspots.map(e => e.id))
      this.entries = responseData.data;
    },

    left(value) {
      const position = JSON.parse(value);
      return (position.left * this.imgWidth) / position.relativeTo.width + "px";
    },

    top(value) {
      const position = JSON.parse(value);
      return (
        (position.top * this.imgHeight) / position.relativeTo.height + "px"
      );
    },

    valToLetter(val) {
        const toChange = val.number ? val.number + this.currentHotspotLength : val.sequence + this.currentHotspotLength

        if (!toChange) return 'A'
        if(toChange > 26) return `${(Number(toChange - 26) + 9).toString(36).toUpperCase()}1`
        return (Number(toChange) + 9).toString(36).toUpperCase()
        
    },

    handleStep(value) {
      this.seen.push(this.isActive);
      this.isActive = value;
    },

    handleSeen(value) {
        this.seen.push(this.isActive);
        const measurements = this.entries[this.isActive].measurement.filter(el => el.is_mandatory === 1).map(el => el.id)

        const missingLogs = measurements.map(el => this.addedNotes.includes(el)).filter(el => !el)
        
        this.error = ''

        switch (value) {
            case "less":
                if (this.isActive >= 0) this.isActive -= 1;
                break;
            case "more":
                if(missingLogs.length) {
                    this.error = 'You need to fill all required notes'
                    return
                }
                if (this.isActive <= this.entries.length - 1) this.isActive += 1;
                break;
            case "close":
                if(missingLogs.length) {
                    this.error = 'You need to fill all required notes'
                    return
                }
                this.$emit('close')
                break;
        }
    },

    imgLoaded() {
      this.showMarkers = true;
      this.imgWidth = this.$refs.mainImage.offsetWidth - 25;
      this.imgHeight = this.$refs.mainImage.offsetHeight - 25;
      this.isImgLoaded = true
    },

    async goToDocument(doc) {
      window.open(this.imgURL + doc.file);
    },

    addNote(measure) {
        this.error = ''
        this.showNotes.push(measure)
    },

    handleAdded(id) {
        this.addedNotes.push(id)
    }
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.slide-up-enter-active {
  animation: slide-up 0.5s;
}
.slide-up-leave-active {
  animation: slide-up 0.5s reverse;
}
.step-container {
  width: 40rem;
  height: 90vh;
  background: white;
  user-select: none;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 1px 8px #142e6e1a;
  overflow: hidden;

  header {
    width: 100%;
    min-height: 8vh;
    height: 8vh;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 8px #142e6e1a;
    z-index: 1;
    background: white;

    .back {
      position: absolute;
      left: 20px;
      cursor: pointer;
    }

    .title {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  section {
    height: 74vh;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .steps {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .step {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #0515244d;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 500;
        cursor: pointer;

        &.seen {
          background: #34c759;
        }

        &.active {
          background: var(--primary-color);
        }
      }

      .separator {
        font-size: 1.2rem;
      }
    }
    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .img {
        max-height: 20rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .hotspot {
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .hotspots {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 25px;

        .marker {
          display: flex;
          gap: 5px;
          text-transform: capitalize;
          span {
            &:first-of-type {
              font-weight: bold;
              min-width: 4%;
              text-align: right;
            }
          }
        }
      }
    }

    .tools {
        display: flex;
        flex-direction: column;
        padding-inline: 25px;

        .tool {
            margin-top: 12px;
            display: flex;
            align-items: center;

            figure {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 50%;
                border: solid 1px var(--primary-color);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-user-drag: none;
                }
            }
            
            span {
                margin-left: 12px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    .description,
    .documents,
    .measurements,
    .notes {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 25px;
    }

    .documents {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      .doc-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .desc {
          display: flex;
          gap: 0.5rem;
          cursor: pointer;
          user-select: none;
          figure {
            width: 30px;
            height: 30px;
            position: relative;
            img {
              height: 100%;
              object-fit: cover;
            }

            span {
              background: var(--primary-color);
              color: white;
              padding: 1px 5px;
              border-radius: 15px;
              position: absolute;
              bottom: -5px;
              right: 0;
              font-size: 8px;
              text-transform: lowercase;
            }
          }
          .doc-desc {
            display: flex;
            flex-direction: column;
            .name {
              font-weight: 600;
            }
            .extra {
              font-size: 0.7rem;
            }
          }
        }
      }
    }

    .measurements {
      .measure-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;
          gap: 2rem;
          .measure-desc {
            .title {
              font-weight: 500;

              span {
                &.filled {
                    color: #34c759;
                }

                &.unfilled {
                    color: $red;
                }
              }
            }
          }
          .value {
            display: flex;
            gap: 0.5rem;
            .val {
              font-weight: 500;
            }
          }
        }

        .measure-notes {
          cursor: pointer;
          user-select: none;
          text-decoration: underline;
          transition: 0.2s;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  footer {
    width: 100%;
    min-height: 8vh;
    height: 8vh;
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 8px #142e6e1a;
    z-index: 1;
    padding: 0 50px;
    background: white;

    span {
      user-select: none;
      cursor: pointer;
    }

    .previous {
      text-decoration: underline;
      &.hidden {
        opacity: 0;
        cursor: default;
      }
    }

    .next {
      padding: 10px 25px;
      background: var(--primary-color);
      color: white;
      border-radius: 5px;
      &.hidden {
        opacity: 0;
        cursor: default;
      }
    }
  }
}

.title {
  font-weight: bold;
}

.no-steps {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>