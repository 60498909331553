<template>
    <section>
        <div class="user">
            <figure>
                <img v-if="item.user.avatar" :src="imgURL + item.user.avatar" alt="">
                <span v-else class="no-img">
                    {{item.user.name.charAt(0)}}
                </span>
            </figure>
            <div class="data">
                <span class="name">{{item.user.name}}</span>
                <div class="date">{{time}}</div>
            </div>
        </div>
        <div class="note">
            <div class="value">Noted: {{item.measurement_value}} {{item.measurement_details.scale}}</div>
            <div class="comment" v-if="item.note">"{{item.note}}"</div>
        </div>
    </section>
</template>

<script>
import timeSince from '@/mixins/timeSince'
export default {
    props: {
        item: {
            type: Object
        }
    },

    mixins: [timeSince],

    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    computed: {
        time() {
            return this.timeSince(this.item.created_at)
        }
    },
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border-left: solid 2px var(--primary-color);
    box-shadow: 0px 0px 3px $grey;
    padding: 10px 20px;
    .user {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        figure {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px var(--primary-color);

            img, span {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                background: var(--primary-color);
                color: white;
                font-weight: 500;
            }
        }

        .data {
            .name {
                font-weight: 500;
            }

            .date {
                font-weight: 400;
                font-size: 0.7rem;
                color: $grey;
            }
        }
    }

    .note {
        margin-left: calc(40px + 0.5rem);

        .value {
            font-weight: 600;
        }

        .comment {
            font-style: italic;
            padding: 10px;
            width: fit-content;
            max-width: 70%;
        }
    }
}
</style>