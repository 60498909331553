<template>
    <div class="preview-container">
        <step v-if="type === 'step'" :data="info" @close="$emit('close')" />
        <step-desktop v-if="type === 'step-desktop'" :data="info" @close="$emit('close')" />
        <folder v-if="type === 'folder'" :data="info" @close="$emit('close')" />
    </div>
</template>

<script>
import Step from './Step/Index.vue';
import StepDesktop from './StepDesktop/Index.vue';
import Folder from './Folder/Index.vue';
export default {
    props: {
        info: {
            type: Object,
            default: () => {},
            required: false,
        },
        type: {
            type: String,
            default: () => {},
            required: true,
        },
    },

    components:{
		Step,
        StepDesktop,
        Folder
    },
}
</script>

<style scoped>
    .preview-container {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: #f2f5f88c;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
    }
</style>