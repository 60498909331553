<template>
    <div class="step-container">
        <header>
            <div class="back" @click="$emit('close')">
                <img src="/assets/images/NavBackButton.svg" alt="">
            </div>
            <div class="title">{{info.title}}</div>
        </header>
        <main v-if="info">
            <div class="image" v-if="info.extras.image.info.length">
                <img ref="mainImage" :src="info.extras.image.info[0].link" @load="imgLoaded">
                <template v-if="showMarkers">
                    <div class="hotspot"
                    v-for="(marker, index) in info.extras.image.hotspots" :key="index"
                    :id="marker.number"
                    :style="{ left:(marker.position.left * imgWidth) / marker.position.relativeTo.width  + 'px', top:(marker.position.top * imgHeight) / marker.position.relativeTo.height + 'px', background: marker.color }"
                    :title="marker.description"
                    >
                            {{valToLetter(marker)}}
                    </div>
                    <div class="markers">
                        <div class="marker" v-for="(marker, index) in info.extras.image.hotspots" :key="index">
                            <span>{{valToLetter(marker)}}</span>
                            <span>{{marker.description}}</span>
                        </div>
                    </div>
                </template>
            </div>
            <hr v-if="info.description.length" />
            <div v-if="info.description.length" class="description">
                <div class="title">description</div>
                <span>{{info.description}}</span>
            </div>
            <hr v-if="info.tools.length" />
            <div v-if="info.tools.length" class="description">
                <div class="title">Tools</div>
                <template v-for="(tool, i) in info.tools" :key="i">
                    <span>{{ tool.name }}</span>
                </template>
            </div>
            <hr v-if="info.extras.files.length" />
            <div class="documents" v-if="info.extras.files.length">
                <div class="title">documents</div>
                <div class="document" v-for="(document, index) in info.extras.files" :key="index">
                    <div class="icon">
                        <img src="/assets/images/doc-icon.svg" alt="">
                    </div>
                    <div class="doc-info">
                        <span class="name">{{document.name}}</span>
                        <span class="view">Quick View</span>
                    </div>
                </div>
            </div>
            <hr v-if="info.extras.measurements.length" />
            <div class="measurements" v-if="info.extras.measurements.length">
                <div class="title">measurements</div>
                <div class="measure" v-for="(measure, index) in info.extras.measurements" :key="index">
                    <div class="measure-info">
                        <div class="info">
                            <span class="name">{{measure.variable}}</span>
                            <div class="values">
                                <span class="value">{{measure.value}}</span>
                                <span class="scale">{{measure.scale}}</span>
                            </div>
                        </div>
                        <template v-if="measure.notes">
                            <div class="notes">
                                <span class="name">Notes</span>
                                <textarea placeholder="Insert Notes here"></textarea>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
            required: false,
        },
    },

    data() {
        return {
            imgWidth: 0,
            imgHeight: 0,
            showMarkers: false,
            info: this.data || []
        }
    },

    methods: {
        imgLoaded() {
            this.showMarkers = true
            this.imgWidth = this.$refs.mainImage.width - 25
            this.imgHeight = this.$refs.mainImage.height - 25
        },

        valToLetter(val) {
            const toChange = val.number ? val.number : val.sequence
            if(toChange) return (Number(toChange) + 9).toString(36).toUpperCase()
            return 'A'
        },
    },
}
</script>

<style lang="scss" scoped>
    .step-container {
        width: 40rem;
        height: 90vh;
        background: white;
        user-select: none;
        display: flex;
        flex-direction: column;
        position: relative;
		box-shadow: 0px 1px 8px #142E6E1A;
    }

    .step-container header {
        width: 100%;
        height: 75px;
        display: flex;
        position: sticky;
        top: 0;
        justify-content: center;
        align-items: center;
		box-shadow: 0px 1px 8px #142E6E1A;
        z-index: 1;
    }

    .step-container header .back {
        position: absolute;
        left: 20px;
        cursor: pointer;
    }

    .step-container header .title {
        text-transform: uppercase;
        font-weight: 600;
    }

    .step-container main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-x: auto;
        padding: 20px 0;
    }


    .step-container main::-webkit-scrollbar {
        width: 5px;
    }

    .step-container main::-webkit-scrollbar-thumb {
        background: var(--primary-color);
    }


    .step-container main .steps {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .step-container main .steps .step {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #0515244D;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 500;
    }

    .step-container main .steps .green {
        background: #34C759;
    }

    .step-container main .steps .blue {
        background: var(--primary-color);
    }

    .step-container main .image {
        position: relative;
        padding: 0px 25px;
    }

    .step-container main .image img {
        max-height: 550px;
        object-fit: contain;
    }

    .step-container main .image .hotspot{
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step-container main .image .markers {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .step-container main .image .markers .marker {
        padding: 10px 25px 0;
        display: flex;
        gap: 10px;
        text-transform: capitalize;
    }

    .step-container main .image .markers .marker span:first-of-type{
        font-weight: bold;
        width: 15px;
    }

    hr {
        border: solid 1px #0515241a;
        margin: 0 25px;
    }

    .step-container main .measurements,
    .step-container main .description,
    .step-container main .documents {
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .title {
        text-transform: capitalize;
        font-weight: 600;
    }

    .step-container main .documents .document {
        display: flex;
        gap: 10px;
    }

    .step-container main .documents .document .icon {
        display: flex;
        align-items: center;
    }

    .step-container main .documents .document .doc-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .step-container main .documents .document .doc-info .name {
        font-weight: 600;
    }

    .step-container main .documents .document .doc-info .view {
        color: var(--primary-color);
        font-size: 0.8rem;
        text-decoration: underline;
        cursor: pointer;
    }

    .step-container main .measurements {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .step-container main .measurements .measure .measure-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .step-container main .measurements .measure .measure-info .info,
    .step-container main .measurements .measure .notes {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .step-container main .measurements .measure .measure-info .name,
    .step-container main .measurements .measure .notes .name {
        font-weight: 500;
    }

    .step-container main .measurements .measure .measure-info .values {
        display: flex;
        width: 100px;
        justify-content: space-between;
        padding: 10px;
        border: solid 1px #CAD6E2;
        border-radius: 5px;
    }

    .step-container main .measurements .measure .measure-info .values .scale{
        opacity: 0.5;
    }
    .step-container main .measurements .measure .notes textarea {
        resize: none;
        border: solid 1px #CAD6E2;
        border-radius: 5px;
        padding: 10px;
    }

    .step-container main .measurements .measure .notes textarea:focus {
        outline: none;
    }

    .step-container main .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 25px;
    }

    .step-container main .buttons .btn {
        text-transform: uppercase;
        background: #f2f5f8;
        border-radius: 30px;
        padding: 10px 0;
        transition: 0.2s;
    }

    .step-container main .buttons .btn:hover {
        background: #d0d1d3;
    }

    .step-container main .buttons .next {
        background: var(--primary-color);
        color: white;
    }

    .step-container main .buttons .next:hover {
        background: #1d4dbd;
    }
</style>